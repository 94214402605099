ll
<template>
  <v-row>
    <v-col md="12">
      <v-card>
        <v-card-title>
         <!-- <v-icon
            @click="pushRouteToView('employees')"
            class="mr-4"
            style="cursor: pointer"
          >
            mdi-arrow-left
          </v-icon>-->
          <v-btn
              small
              @click="pushRouteToView()"
              class="mr-4 btn-back"
            >
              <v-icon> mdi-arrow-left </v-icon>
              Voltar
            </v-btn>
          <v-icon class="mr-2">mdi-account-hard-hat</v-icon>
         <span v-if="id">Editar </span><span v-else>Adicionar </span> &nbsp;Funcionário
          <v-spacer></v-spacer>
        </v-card-title>

        <v-card-text>
          <v-form
            v-model="submitNewEmployee"
            ref="submitNewEmployee"
            @submit.prevent="submitEmployee(employee.id)"
          >
            <v-row>
              <v-col xl="8" lg="8" md="8" sm="12">
                <v-text-field
                  label="Nome"
                  name="name"
                  outlined
                  v-model="employee.name"
                  :rules="textRules"
                  append-icon="mdi-account"
                  dense
                />
              </v-col>

              <v-col xl="4" lg="4" md="4" sm="12">
                <v-text-field
                  outlined
                  label="CPF"
                  name="cpf"
                  v-model="employee.cpf"
                  v-mask="['###.###.###-##']"
                  :rules="textRules"
                  append-icon="mdi-card-account-details-outline"
                  dense
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col xl="12" lg="12" md="12" sm="12">
                <h3>Categorias</h3>
                <hr/>
              </v-col>

              <v-col xl="8" lg="8" md="8" sm="12">
                <client-categories-select @selected="UpdateCategoriesEvent">
                </client-categories-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col sm="12" class="text-right">
                <v-btn type="submit" color="success">
                  <v-icon left> mdi-content-save</v-icon>
                  Salvar
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import {mask} from "vue-the-mask";
import {mapGetters} from "vuex";
import store from "@/store";

import {EMPLOYEE_CREATE, EMPLOYEE_GET, EMPLOYEE_UPDATE} from "@/store/actions/employees.type";
import {
  EMPLOYEE_RESET,
} from "@/store/mutations/employees.type";

import {CHANGE_PROGRESS} from "@/store/mutations/mutations.type";
import {CATEGORY_RESET_STATE} from "@/store/mutations/categories.type";

export default {
  name: "Employees",

  props: {
    id: {
      default: 0,
      type: Number,
      required: false,
    },
  },

  directives: {mask},

  async beforeRouteUpdate(to, from, next) {
    // Reset state if user goes from /editor/:id to /editor
    // The component is not recreated so we use to hook to reset the state.
    await store.commit(EMPLOYEE_RESET);
    await store.commit(CATEGORY_RESET_STATE);
    return next();
  },
  async beforeRouteEnter(to, from, next) {
    await store.commit(EMPLOYEE_RESET);
    if(to.params.id !== undefined){
      await store.dispatch(EMPLOYEE_GET, to.params.id)
    }
    return next();
  },

  data: () => ({
    submitNewEmployee: false,
    cpfRule: [
      (v) => !!v || "CPF é obrigatório",
      (strCPF) => {
        if (!strCPF) return false;
        strCPF = strCPF.replace(/\D/g, "");
        let Soma;
        let Resto;
        Soma = 0;
        if (strCPF == "00000000000") return "CPF Inválido";
        let i = 0;
        for (i = 1; i <= 9; i++)
          Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
        Resto = (Soma * 10) % 11;

        if (Resto == 10 || Resto == 11) Resto = 0;
        if (Resto != parseInt(strCPF.substring(9, 10))) return "CPF Inválido";

        Soma = 0;
        for (i = 1; i <= 10; i++)
          Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
        Resto = (Soma * 10) % 11;

        if (Resto == 10 || Resto == 11) Resto = 0;
        if (Resto != parseInt(strCPF.substring(10, 11))) return "CPF Inválido";
        return true;
      },
    ],
    textRules: [
      (v) => !!v || "Este campo é obrigatório",
      //v => v > 0 || "Esta campo deve ter um valor maior que zero."
    ],

  }),

  methods: {
    async UpdateCategoriesEvent(event) {
      this.employee.categories = event;
    },
    pushRouteToView(route) {
      //this.$router.push({name: route});
      if (this.id) {

                this.$router.push({

                    name: "employee",

                    params: {id: this.id}

                });

            } else {

                this.$router.push({name: 'employees'});

            }
    },
    submitEmployee(id) {
      if (this.$refs.submitNewEmployee.validate()) {
        let action = id ? EMPLOYEE_UPDATE : EMPLOYEE_CREATE;

        this.$store.commit(CHANGE_PROGRESS, true);
        
        this.$store
          .dispatch(action)
          .then((res) => {
            this.$store.commit(CHANGE_PROGRESS, false);
            this.$refs.submitNewEmployee.resetValidation()

            this.snackbar.snackbar = true;
            this.snackbar.color = "success";
            this.snackbar.text = "Salvo com sucesso";

            if (res.data.id) {
              this.$router.push({
                name: "employee",
                params: {id: res.data.id},
              });
            } else {
              this.$router.push({
                name: "employee",
                params: {id: id}
              })
            }
          })
          .catch((response) => {
            store.commit(CHANGE_PROGRESS, false)
            this.snackbar.snackbar = true;
            this.snackbar.text = `Erro ao Salvar funcionário`;
            this.snackbar.color = "error";
            if (typeof response.data.message !== "undefined") {
              this.snackbar.text = response.data.message;
            }
          });
      } else {
        this.snackbar.snackbar = true;
        this.snackbar.color = "error";
        this.snackbar.text = "Preencha o formulário corretamente";
      }
    },
  },

  computed: {
    ...mapGetters(["employee", "categories", "checkUsers", "snackbar"]),

    getCategoryNames() {
      const store = this.$store.getters.categories;
      const map = store.map((item) => item.name);
      return map;
    },
  },
  components: {
    "client-categories-select": () =>
      import("@/components/ClientCategoriesSelect"),
  },
};
</script>
